
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import TwoFactorAuthenticationSetup from "@/shared/components/Authenticator/TwoFactorAuthenticationSetup.vue";
import PageHeader from "@/shared/components/Layouts/PageHeader.vue";
import { useRouter } from "vue-router";
import routeNames from "@/web/router/routeNames";
import BrandLogo from "@/shared/components/Layouts/BrandLogo.vue";
import { useStore } from "vuex";

export default defineComponent({
  props: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const isRevoked = computed(
      () => router.currentRoute?.value?.params?.action === "revoked"
    );

    const handleFinishSetUp = async () => {
      await router.replace({ name: routeNames.settings });
    };

    const handleLogout = async () => {
      await store.dispatch("logoutSuccess");
      await router.push({ name: routeNames.login });
    };

    return {
      t,
      isRevoked,
      handleFinishSetUp,
      handleLogout,
    };
  },
  methods: {},
  components: { TwoFactorAuthenticationSetup, PageHeader, BrandLogo },
});
