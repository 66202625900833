
import { defineComponent } from "vue";
import brandLogoSrc from "@/assets/logo.svg";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
  },
  setup() {
    return { brandLogoSrc };
  },
  components: {},
});
