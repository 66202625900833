import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "settings-two-factor-setup" }
const _hoisted_2 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrandLogo = _resolveComponent("BrandLogo")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_TwoFactorAuthenticationSetup = _resolveComponent("TwoFactorAuthenticationSetup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageHeader, {
      title: _ctx.t('Set up new 2FA')
    }, {
      "header-action": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BrandLogo, { class: "brand-logo" }),
          _createVNode(_component_a_button, {
            onClick: _ctx.handleLogout,
            type: "link",
            size: "small",
            class: "p-0 text-right",
            style: {"height":"1.5rem"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Log out")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_TwoFactorAuthenticationSetup, {
      onFinish: _ctx.handleFinishSetUp,
      revoke: _ctx.isRevoked,
      generate2fa: ""
    }, null, 8, ["onFinish", "revoke"])
  ]))
}